/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import Modal from "../../../components/Modal/Modal"
import { Table, Tr, Td } from "../../../components/Table/TableElements"
import ReactToPrint from "react-to-print"
import Button from "../../../components/Elements/Button"
import { Link } from "gatsby"
import ImageCardSmall from "../../../components/Elements/ImageCardSmall"
import CircleAvatar from "../../../components/Elements/CircleAvatar"
import ImageCardSmallPng from "../../../assets/images/image_coming_soon.png"
import CartContext from "../../../context/Cart/CartContext"
import { cancelOrder } from "../../../helpers/DrupalHelper"
import StateContext from "../../../context/state/StateContext"
import NotificationContext from "../../../context/Notification/NotificationContext"

const MyOrdersModal = ({ openOrder, setOpenOrder, digital, onDownload }) => {

  const { state } = useContext(StateContext)
  const { token } = state
  const { toggleNotification } = useContext(NotificationContext)

  console.log(token, "tokenized")
  const { showCartModal } = useContext(CartContext)
  const [open, setOpen] = useState(false)
  const [confirmation, setConfirmation] = useState(false)
  console.log(openOrder)
  useEffect(() => {
    if (openOrder === null) return setOpen(false)
    return setOpen(true)
  }, [openOrder])
  const SmallRow = props => {
    return (
      <Tr>
        {props.label && (
          <Td className="!font-semibold !text-[#222]">{props.label}</Td>
        )}
        <Td className="!whitespace-normal">{props.value}</Td>
      </Tr>
    )
  }

  const ref = useRef()
  const data = {
    available_total: 184,
    in_stock: true,
    max_qty: 2,
    max_qty_frequency: "monthly",
    user_group: "32",
    status: "can_order",
    max_qty_ordered: 2,
    ordered_last: "26/04/2024",
    stores: [
      { id: "54233", max_qty_ordered: 0, ordable_value: true },
      { id: "51927", max_qty_ordered: 0, ordable_value: true },
      { id: "50593", max_qty_ordered: 1, ordable_value: true },
    ],
    count: 1,
    assetData: {
      id: "37340",
      title: "Weed Me : Scotti's Cake 1x1g Tent Card",
      description: "Weed Me : Scotti's Cake 1x1g Tent Card",
      files: 1,
      type: "physical",
      cover: [
        "https://useo2o.s3.ca-central-1.amazonaws.com/d/b/2024-02/Dart%20%2816%29.png?VersionId=5k63nroZh_tiP4UW3ex7dWEA_MrxbCPQ",
      ],
      sku: "148340",
      tags: ["Tent card"],
      bundle: "default",
      category: ["Print Materials"],
      brand_name: "Weed Me",
      brand_logo:
        "https://useo2o.s3.ca-central-1.amazonaws.com/d/b/2022-11/Weed%20Me%20-%20O2O%20Logo.png?VersionId=B0DDgbfdsDEFqd4EXfBmWPP_pnfQ7Ocj",
      brand_id: "209",
      last_change: "26/04/2024",
      status: {
        available_total: 184,
        in_stock: true,
        max_qty: 2,
        max_qty_frequency: "monthly",
        user_group: "32",
        status: "can_order",
        max_qty_ordered: 2,
        ordered_last: "26/04/2024",
        stores: [
          {
            id: "54233",
            max_qty_ordered: 0,
            ordable_value: true,
          },
          {
            id: "51927",
            max_qty_ordered: 0,
            ordable_value: true,
          },
          {
            id: "50593",
            max_qty_ordered: 1,
            ordable_value: true,
          },
        ],
      },
      price: "0.00",
      bookmarked: false,
      products: [],
    },
  }
  const totalProductPrice = !digital
    ? openOrder?.items?.reduce((preValue, currValue) => {
      // console.log(currValue,'curr!')
      return (
        preValue +
        parseFloat(currValue?.price) * parseInt(currValue?.quantity)
      )
    }, 0)
    : 0
  const totalProductWithTex = (
    (13 / 100) * totalProductPrice?.toFixed(2) +
    totalProductPrice
  )?.toFixed(2)

  console.log(totalProductPrice)
  return (
    <>
      {open && openOrder && (
        <Modal
          isOpen={open}
          closeModal={() => {
            setOpenOrder(null)
            // setOpen(false)
          }}
          md
          title={openOrder.order_id}
        >
          <div ref={ref}>
            <h1 className="pb-8 text-med-16">Your Order</h1>

            <div className="flex max-md:flex-col max-md:gap-y-4">
              {!digital && (
                <div className="w-2/5 max-md:w-full md:pr-4">
                  <Table fit>
                    {
                      <SmallRow
                        label="Shipment Number"
                        value={openOrder.shipment_number || "Not Available"}
                      />
                    }
                    <SmallRow label="Order Date" value={openOrder.order_date} />
                    <SmallRow
                      label="Gift Order"
                      value={
                        openOrder.gift_order === null ||
                          openOrder.gift_order === 0 ||
                          openOrder.gift_order === "0"
                          ? "No"
                          : "Yes"
                      }
                    />
                    <SmallRow
                      label="Status"
                      value={
                        openOrder.status
                          ? openOrder.status === "Archived"
                            ? "Shipped"
                            : openOrder.status
                          : "Processing"
                      }
                    />
                    <SmallRow
                      label="Tracking"
                      value={
                        openOrder.status_url === null ? (
                          "None Provided"
                        ) : (
                          <a
                            target="_blank"
                            href={openOrder.status_url}
                            className=""
                          >
                            <div className="flex items-center space-x-2">
                              <span>Shipment</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={1}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                />
                              </svg>
                            </div>
                          </a>
                        )
                      }
                    />
                    {/*<SmallRow label="Your Subtotal" value={openOrder.subtotal} />*/}
                    {/*<SmallRow label="Your Total" value={openOrder.total} />*/}
                  </Table>
                </div>
              )}
              <div className="w-3/5 max-md:w-full">
                {!digital ? (
                  <Table fit>
                    <SmallRow
                      value={
                        <>
                          <div className="">
                            <h5 className="mb-3 text-med-15">
                              Delivery Address
                            </h5>
                            {openOrder.company && (
                              <>
                                {" "}
                                {openOrder.company} <br />
                              </>
                            )}
                            {openOrder.first_name && openOrder.last_name && (
                              <>
                                {" "}
                                {openOrder.first_name} {openOrder.last_name}
                                <br />
                              </>
                            )}
                            {openOrder.address1 && (
                              <>
                                {" "}
                                {openOrder.address1} <br />
                              </>
                            )}
                            {openOrder.address2 && (
                              <>
                                {" "}
                                {openOrder.address2} <br />
                              </>
                            )}
                            {openOrder.city} {openOrder.province}{" "}
                            {openOrder.zip}
                            <br />
                            {openOrder.country && (
                              <>
                                {" "}
                                {openOrder.country} <br />
                              </>
                            )}
                            {openOrder.phone && (
                              <>
                                {" "}
                                {openOrder.phone} <br />
                              </>
                            )}
                          </div>
                        </>
                      }
                    />
                    <SmallRow label="Notes" value={openOrder.note || ""} />
                  </Table>
                ) : (
                  <Table fit>
                    <SmallRow
                      value={
                        <>
                          <div className="">
                            <h5 className="mb-3 text-med-15">
                              Delivery Address
                            </h5>
                            {openOrder.company && (
                              <>
                                {" "}
                                {openOrder.company} <br />
                              </>
                            )}
                            {openOrder.first_name && openOrder.last_name && (
                              <>
                                {" "}
                                {openOrder.first_name} {openOrder.last_name}
                                <br />
                              </>
                            )}
                            {openOrder.location_name && (
                              <>
                                {" "}
                                {openOrder.location_name} <br />
                              </>
                            )}
                          </div>
                        </>
                      }
                    />
                  </Table>
                )}
              </div>
            </div>
            <h1 className="pt-8 pb-8 text-med-16">Items in this order</h1>
            {!digital ? (
              <Table
                columns={[
                  "Asset",
                  "Brand",
                  "SKU",
                  "Qty Ordered",
                  "Qty Shipped",
                  "Price",
                ]}
              >
                {openOrder?.items?.map((i, k) => {
                  return (
                    <Tr key={k}>
                      <Td className="!whitespace-normal lg:min-w-[250px] lg:w-[250px]">
                        <Link
                          to={`/asset/${i.asset_id}`}
                          className="flex items-center space-x-4"
                        >
                          <ImageCardSmall
                            size={60}
                            image={i.asset_cover_image || ImageCardSmallPng}
                            alt={i.title}
                          />
                          <div className="flex flex-col">
                            <span
                              css={css`
                                line-height: 1.3;
                                font-size: 13px;
                              `}
                            >
                              {i.title}
                            </span>
                          </div>
                        </Link>
                      </Td>
                      <Td className="!whitespace-normal ">
                        <Link to={`/brand/${i.brand?.id}`}>
                          <CircleAvatar
                            sm
                            border
                            text={i.brand.name}
                            alt={i.brand.name}
                            image={i.brand.logo}
                          />
                        </Link>
                      </Td>
                      <Td className="!text-[13px]">{i.sku}</Td>
                      <Td className="!text-[13px]">{i.quantity}</Td>
                      <Td className="!text-[13px]">{i.quantity_shipped}</Td>
                      <Td className="!text-[13px]">${i.price}</Td>
                      {/*<Td>
                    {i.price_set.shop_money.amount}{" "}
                    {i.price_set.shop_money.currency_code}
                  </Td>*/}
                    </Tr>
                  )
                })}
                {openOrder.total !== "0.00" && (
                  <>
                    <Tr>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td className="!text-[13px]">
                        <b>Subtotal</b>
                      </Td>
                      <Td className="!text-[13px]">
                        ${totalProductPrice.toFixed(2) || "0.00"}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td className="!text-[13px]">
                        <b>HST @ 13%</b>
                      </Td>
                      <Td className="!text-[13px]">
                        $
                        {((13 / 100) * totalProductPrice.toFixed(2)).toFixed(
                          2
                        ) || "0.00"}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td></Td>
                      <Td className="!text-[13px]">
                        <b>Total</b>
                      </Td>
                      <Td className="!text-[13px]">
                        ${totalProductWithTex || "0.00"}
                      </Td>
                    </Tr>
                  </>
                )}
              </Table>
            ) : (
              <Table columns={["Asset", "Brand", "File Type"]}>
                <Tr>
                  <Td className="!whitespace-normal lg:min-w-[250px] lg:w-[250px]">
                    <Link
                      to={`/asset/${openOrder.asset_id}`}
                      className="flex items-center space-x-4"
                    >
                      <ImageCardSmall
                        size={60}
                        image={openOrder.asset_cover_image || ImageCardSmallPng}
                        alt={openOrder.title}
                      />
                      <div className="flex flex-col">
                        <span
                          css={css`
                            line-height: 1.3;
                            font-size: 13px;
                          `}
                        >
                          {openOrder.title}
                        </span>
                      </div>
                    </Link>
                  </Td>
                  <Td className="!whitespace-normal ">
                    <CircleAvatar
                      sm
                      border
                      text={openOrder.brand_name}
                      alt={openOrder.brand_name}
                      image={openOrder.brand_logo}
                    />
                  </Td>
                  <Td>
                    <div className="flex flex-col text-sm text-gray-500">
                      {Array.from(
                        new Set(openOrder.file_types?.split(","))
                      ).map((i, k) => (
                        <span className="uppercase text-semibold-11">
                          {i
                            .replaceAll("video/", "")
                            .replaceAll("image/", "")
                            .replaceAll("audio/", "")
                            .replaceAll("application/", "")}
                        </span>
                      ))}
                    </div>
                  </Td>
                </Tr>
              </Table>
            )}
          </div>
          <div className="flex flex-wrap items-center justify-between mt-6 gap-y-3 w-100">
            <div>
              {!digital && (
                <Button
                  className={`!bg-sky-500 !text-white !border-none`}
                  onClick={() => setConfirmation(true)}
                >
                  Cancel Order
                </Button>
              )}
              <Modal
                isOpen={confirmation}
                closeModal={() => {
                  setConfirmation(false)
                }}
                title={"Are you sure?!"}
                titleColor={"!text-[#e43d30]"}
              >
                <p className="text-med-18">
                  If you click ‘Yes”, our warehouse team will cancel this order
                  and return all inventory for these selected assets.
                </p>
                <p className="mt-5 text-med-18">
                  An order cancelation cannot be undone.
                </p>
                <div className="flex justify-between mt-5 item-center">
                  <Button
                    className={`!bg-[#e43d30] !text-white !border-none`}
                    onClick={async () => {
                      const x = await cancelOrder(token, openOrder._id)

                      if (x.status === 200) {
                        setOpenOrder(null)
                        toggleNotification({
                          error: false,
                          content: `Your order has been canceled.`,
                        })

                      } else {
                        toggleNotification({
                          error: true,
                          content: `Sorry, we were unable to cancel your order. Please contact support.`,
                        })
                      }

                      setConfirmation(false)
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className={`!bg-sky-500 !text-white !border-none`}
                    onClick={() => {
                      setConfirmation(false)
                    }}
                  >
                    No
                  </Button>
                </div>
              </Modal>
            </div>
            <div className="flex items-center gap-4">
              {!digital ? (
                <></>
              ) : (
                <Button
                  className={`!bg-[#e43d30] !text-white !border-none`}
                  onClick={() => {
                    onDownload(openOrder.asset_id)
                  }}
                >
                  Download
                </Button>
              )}
              <ReactToPrint
                trigger={() => <Button>Print</Button>}
                content={() => ref.current}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default MyOrdersModal
