/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useRef, useContext, useState } from "react"
import { getUserDownloads } from "../../../helpers/DrupalHelper"
import StateContext from "../../../context/state/StateContext"
import Skeleton from "../../../components/Skeleton"
import DownloadTable from "../../../components/Table/DownloadTable"
import NotificationContext from "../../../context/Notification/NotificationContext"
import Pagination from "../../../components/Pagination"
import MyOrdersContext from "../../../context/MyOrders/MyOrdersContext"

const Digital = props => {
  const { state } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)

  const { filters, requestFilters } = useContext(MyOrdersContext)

  const [loading, setLoading] = useState(true)
  const [orders, setOrders] = useState(null)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [pageNum, setPageNum] = useState(1)
  const perPage = 15

  const scrollRef = useRef()

  // useEffect(async () => {
  //   if (orders === null) getOrders(pageNum)
  // }, [])

  useEffect(() => {
    setTotalPages(Math.ceil(total / perPage))
  }, [total])

  useEffect(async () => {
    if (requestFilters.company !== null) {
      getOrders(pageNum)
    }
  }, [requestFilters])

  const getOrders = async pageNumber => {
    if (loading === false) setLoading(true)
    try {
      let updatedFilters = requestFilters
      delete updatedFilters.status
      const data = await getUserDownloads(
        state.token,
        updatedFilters,
        pageNumber
      )
      setOrders(data.results)
      setTotal(data.total)
      setLoading(false)
      setPageNum(pageNumber)
    } catch (e) {
      console.log(e)
      setLoading(false)
      toggleNotification({
        error: true,
        content:
          "There was an error retrieving your orders. Please contact O2O.",
      })
    }
  }

  return (
    <div ref={scrollRef}>
      {Array.isArray(orders) ? (
        orders.length > 0 ? (
          <>
            <DownloadTable
              data={orders}
              token={state.token}
              toggleNotification={toggleNotification}
            />
            <Pagination
              page={pageNum}
              setPage={v => getOrders(v)}
              totalPages={totalPages}
              scrollRef={scrollRef}
            />
          </>
        ) : (
          "No items to display"
        )
      ) : (
        <Skeleton table />
      )}
    </div>
  )
}

export default Digital
