/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useRef, useContext, useState } from "react"
import { getUserOrders } from "../../../helpers/DrupalHelper"
import StateContext from "../../../context/state/StateContext"
import Skeleton from "../../../components/Skeleton"
import MyOrdersTable from "../../../components/Table/MyOrdersTable"
import NotificationContext from "../../../context/Notification/NotificationContext"
import Pagination from "../../../components/Pagination"
import MyOrdersContext from "../../../context/MyOrders/MyOrdersContext"
import { ClipboardListIcon } from "@heroicons/react/outline"
import {
  faTruck,
  faCalendarDays,
  faEnvelope,
  faUser,
  faHandHoldingHeart,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tag from "../../../components/Elements/Tag"
import MyOrdersModal from "./MyOrdersModal"

export const PhysicalBoxCard = ({ data }) => {
  console.log(data)

  const [openOrder, setOpenOrder] = useState(null)

  return (
    <div className="flex flex-col gap-5">
      <MyOrdersModal openOrder={openOrder} setOpenOrder={setOpenOrder} />
      {data.map((order, k) => {
        return (
          <div
            className="border border-[#EBEBEB] rounded-[8px] w-full cursor-pointer"
            key={k}
            onClick={() => setOpenOrder(order)}
          >
            <div className="border-b border-[#EBEBEB] py-5 px-6">
              <div className="flex flex-wrap items-center justify-between gap-y-4">
                <div className="flex flex-col justify-center gap-4">
                  <div className="flex items-center gap-2">
                    <ClipboardListIcon className="w-5 h-5" />

                    <span className="text-med-14 !font-bold !whitespace-normal">
                      {order.order_id.replaceAll("Order", "")}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faTruck} />

                    <span className="text-med-14">
                      {order?.shipment_number ? order?.shipment_number : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col justify-center gap-4">
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faEnvelope} />

                    <span className="text-med-14 !font-bold !whitespace-normal">
                      N/A
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faCalendarDays} />

                    <span className="text-med-14">{order.order_date}</span>
                  </div>
                </div>
                <div className="flex">
                  <Tag red={order.status === "Cancelled" ? true : false}>
                    {order.status
                      ? order.status === "Archived"
                        ? "Shipped"
                        : order.status
                      : "Processing"}
                  </Tag>
                </div>
              </div>
            </div>
            <div className="p-6">
              <div className="flex flex-col gap-4">
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faUser} />
                  <span className="text-med-14 !whitespace-normal">
                    {order.first_name} {order.last_name}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faBuilding} />
                  <span className="text-med-14 !whitespace-normal">
                    {order.location_name}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  <FontAwesomeIcon icon={faHandHoldingHeart} />
                  <div className="flex flex-wrap items-center gap-20 max-[400px]:gap-5">
                    <div className="flex items-center">
                      <span className="text-med-14">
                        {order.items.length} asset
                        {order.items.length > 1 && "s"}
                      </span>
                    </div>
                    <div className="flex items-center !whitespace-normal">
                      <span className="text-med-14">300 units</span>
                    </div>
                    <div className="flex items-center">
                      <span className="text-med-14">$30</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const Physical = props => {
  const { state } = useContext(StateContext)
  const { toggleNotification } = useContext(NotificationContext)

  const { filters, requestFilters } = useContext(MyOrdersContext)

  const [loading, setLoading] = useState(true)
  const [orders, setOrders] = useState(null)
  const [total, setTotal] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [pageNum, setPageNum] = useState(1)
  const perPage = 15

  const scrollRef = useRef()

  // useEffect(async () => {
  //   if (orders === null) getOrders(pageNum)
  // }, [])

  useEffect(() => {
    setTotalPages(Math.ceil(total / perPage))
  }, [total])

  useEffect(async () => {
    if (requestFilters.company !== null) {
      getOrders(pageNum)
    }
  }, [requestFilters])

  const getOrders = async pageNumber => {
    if (loading === false) setLoading(true)
    try {
      const data = await getUserOrders(state.token, requestFilters, pageNumber)
      setOrders(data.results)
      setTotal(data.total)
      setLoading(false)
      setPageNum(pageNumber)
    } catch (e) {
      console.log(e)
      setLoading(false)
      toggleNotification({
        error: true,
        content:
          "There was an error retrieving your orders. Please contact O2O.",
      })
    }
  }

  return (
    <div ref={scrollRef}>
      {orders === null || loading === true ? (
        <Skeleton table />
      ) : orders.length > 0 ? (
        <>
          <span className=" text-med-14">
            Showing {orders.length} results matching your filters above
          </span>
          <div className="block pt-4 max-[992px]:hidden">
            <MyOrdersTable
              data={orders}
              token={state.token}
              toggleNotification={toggleNotification}
            />
          </div>
          <div className="hidden pt-4 max-[992px]:block">
            <PhysicalBoxCard
              data={orders}
              token={state.token}
              toggleNotification={toggleNotification}
            />
          </div>
          <Pagination
            page={pageNum}
            setPage={v => getOrders(v)}
            totalPages={totalPages}
            scrollRef={scrollRef}
          />
        </>
      ) : (
        "No items to display"
      )}
    </div>
  )
}

export default Physical
