import { css, jsx } from "@emotion/react"
import React, { useState, useEffect, useContext } from "react"
import MyOrdersContext from "./MyOrdersContext"
import {
  getSubUserListing,
  getDeliveryLocationsByCompanyInternal as getDeliveryLocationsByCompany,
} from "../../helpers/DrupalHelper"
import StateContext from "../state/StateContext"
import DataContext from "../Data/DataContext"
import AuthenticationContext from "../authentication/AuthenticationContext"
import { getFormattedDateForFetch } from "../../utils/getFormattedDateForFetch"

export const MyOrdersProvider = props => {
  let endDate = new Date()
  let startDate = new Date()
  startDate.setMonth(startDate.getMonth() - 12)

  const [dateRange, setDateRange] = useState([
    { startDate, key: "selection", endDate },
  ])

  const { token } = useContext(StateContext).state
  const { currentUserCompaniesAsOp } = useContext(DataContext).data
  const { userType } = useContext(AuthenticationContext)

  const [deliveryLocations, setDeliveryLocations] = useState([])
  const [companyUsers, setCompanyUsers] = useState([])

  const [filters, setFilters] = useState({
    company: null,
    users: [],
    delivery_locations: [],
    start_date: null,
    end_date: null,
    search: "",
    brands: null,
    asset_ids: [],
    status: "all",
  })

  const [prepFilters, setPrepFilters] = useState({
    company: null,
    users: [],
    delivery_locations: [],
    start_date: null,
    end_date: null,
    search: "",
    brands: null,
    asset_ids: [],
    status: "all",
  })

  useEffect(async () => {
    if (filters.company) {
      setPrepFilters(prevFilters => ({
        ...prevFilters,
        company: filters.company.value,
      }))

      if (userType.brandManager === true || userType.retailerManager === true) {
        const data = await getSubUserListing(token, filters.company.value)
        const newData = []
        data.map((item, k) => {
          newData.push({ value: item.id, label: item.name })
        })
        setCompanyUsers(newData)
      }

      const deliveryLocationsByCompany = await getDeliveryLocationsByCompany(
        token,
        filters.company.value
      )
      if (
        deliveryLocationsByCompany &&
        Array.isArray(deliveryLocationsByCompany)
      ) {
        let arr = []
        deliveryLocationsByCompany.map((v, k) => {
          arr.push({
            label: v.title,
            value: v.id,
            location: v.location,
          })
        })
        setDeliveryLocations(arr)
      }
    }
  }, [filters.company])

  useEffect(() => {
    if (filters.asset_ids) {
      setPrepFilters(prevFilters => ({
        ...prevFilters,
        asset_ids: filters.asset_ids.map(v => v.value),
      }))
    }
  }, [filters.asset_ids])

  useEffect(() => {
    if (filters.users) {
      setPrepFilters(prevFilters => ({
        ...prevFilters,
        users: filters.users.map(v => v.value.toString()),
      }))
    }
  }, [filters.users])

  useEffect(() => {
    if (filters.delivery_locations) {
      let arr = []
      filters.delivery_locations.map((v, k) => {
        arr.push(v.value)
      })
      setPrepFilters(prevFilters => ({
        ...prevFilters,
        delivery_locations: arr,
      }))
    }
  }, [filters.delivery_locations])

  useEffect(() => {
    if (dateRange && dateRange.length) {
      setPrepFilters(prevFilters => ({
        ...prevFilters,
        start_date: getFormattedDateForFetch(dateRange[0].startDate),
        end_date: getFormattedDateForFetch(dateRange[0].endDate),
      }))
    }
  }, [dateRange])

  useEffect(() => {
    if (filters.brands) {
      setPrepFilters(prevFilters => ({
        ...prevFilters,
        brands: filters.brands.map(v => v.value),
      }))
    }
  }, [filters.brands])

  useEffect(() => {
    if (filters.search || filters.search === "") {
      setPrepFilters(prevFilters => ({
        ...prevFilters,
        search: filters.search,
      }))
    }
  }, [filters.search])

  useEffect(() => {
    if (filters.status) {
      setPrepFilters(prevFilters => ({
        ...prevFilters,
        status: filters.status,
      }))
    }
  }, [filters.status])

  useEffect(async () => {
    if (currentUserCompaniesAsOp && currentUserCompaniesAsOp.length) {
      if (userType.brandManager === true || userType.retailerManager === true) {
        const data = await getSubUserListing(
          token,
          currentUserCompaniesAsOp[0].value
        )
        const newData = []
        data.map((item, k) => {
          newData.push({ value: item.id, label: item.name })
        })

        setCompanyUsers(newData)
      }

      const deliveryLocationsByCompany = await getDeliveryLocationsByCompany(
        token,
        currentUserCompaniesAsOp[0].value
      )
      if (
        deliveryLocationsByCompany &&
        Array.isArray(deliveryLocationsByCompany)
      ) {
        let arr = []
        deliveryLocationsByCompany.map((v, k) => {
          arr.push({
            label: v.title,
            value: v.id,
            location: v.location,
          })
        })
        setDeliveryLocations(arr)
      }

      setFilters(prevFilters => ({
        ...prevFilters,
        company: currentUserCompaniesAsOp[0],
      }))
    }
  }, [currentUserCompaniesAsOp])
  if (!currentUserCompaniesAsOp || currentUserCompaniesAsOp.length === 0)
    return "Loading"
  return (
    <MyOrdersContext.Provider
      value={{
        filters,
        requestFilters: prepFilters,
        setFilters,
        dateRange,
        setDateRange,
        companyUsers,
        deliveryLocations,
      }}
    >
      {props.children}
    </MyOrdersContext.Provider>
  )
}

export default MyOrdersProvider
