/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState } from "react"
import DataContext from "../context/Data/DataContext"
import ReportingContext from "../context/Reporting/ReportingContext"
import FloatingSelect from "./Elements/Form/FloatingSelect"
import FloatingDateRange from "./Elements/Form/FloatingDateRange"
import MyOrdersContext from "../context/MyOrders/MyOrdersContext"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import SearchInput from "../components/Elements/SearchInput"
import FilterSelect from "./Elements/FilterSelect"
import { entitySuggest, getOrderedAssets } from "../helpers/DrupalHelper"
import StateContext from "../context/state/StateContext"
import AsyncFloatingSelect from "./Elements/Form/AsyncFloatingSelect"

function MyOrdersFilter(props) {
  const {
    dateRange,
    setDateRange,
    filters,
    setFilters,
    companyUsers,
    deliveryLocations,
  } = useContext(MyOrdersContext)
  const { state } = useContext(StateContext)

  const { currentUserCompaniesAsOp } = useContext(DataContext).data
  const { currentUserData } = useContext(AuthenticationContext).authentication
  const { companies } = currentUserData

  const [brandDatas, setBrandDatas] = useState([])
  const [orderedAssets, setOrderedAssets] = useState([])
  const [search, setSearch] = useState("")

  useEffect(() => {
    const getAllBrands = async () => {
      const brandDatas = await entitySuggest(state.token, {
        bundle: ["default"],
        entity: "brand",
      })
      if (brandDatas) {
        setBrandDatas(brandDatas)
      }
    }
    const getAllOrderedAssets = async () => {
      const orderedAssetsRes = await getOrderedAssets(state.token, {
        company: companies[0].id,
      })
      if (orderedAssetsRes) {
        setOrderedAssets(
          orderedAssetsRes.map(item => {
            return {
              label: item.asset_title,
              value: item.asset_id,
            }
          })
        )
      }
    }
    getAllBrands()
    getAllOrderedAssets()
  }, [])

  console.log(companyUsers)

  const { userType } = useContext(AuthenticationContext)
  return (
    <div className={`mb-8`}>
      <div className="grid items-center grid-cols-4 gap-5 max-md:grid-cols-2 max-lg:grid-cols-3 max-[450px]:grid-cols-1">
        <div className="">
          <SearchInput
            placeholder={"Search for anything ..."}
            onBlur={e => {
              setFilters(prevFilters => ({
                ...prevFilters,
                search: e.target.value,
              }))
            }}
            onChange={e => {
              console.log(e.target.value)
              setSearch(e.target.value)
            }}
            onIconClick={() => {
              setFilters(prevFilters => ({
                ...prevFilters,
                search: search,
              }))
            }}
            onKeyDown={e => {
              if (e.key === "Enter") {
                setFilters(prevFilters => ({
                  ...prevFilters,
                  search: search,
                }))
              }
            }}
            showPointer
          />
        </div>

        <div className="">
          <FloatingSelect
            label="Brands Ordered"
            options={
              brandDatas &&
              brandDatas.map(brand => ({
                label: brand.title,
                value: brand.target_id,
              }))
            }
            // value={filters.company}
            onChange={v => {
              console.log(v)
              setFilters(prevFilters => ({
                ...prevFilters,
                brands: v,
              }))
            }}
            isMulti={true}
            required
            className="!mb-0"
          />
        </div>
        <div className="">
          {
            //   <FloatingSelect
            //   label="Assets Ordered"
            //   options={[
            //     {
            //       label: "Company 1",
            //       value: "company1",
            //     },
            //   ]}
            //   // value={filters.company}
            //   onChange={v => {
            //     console.log(v)
            //   }}
            //   // isMulti={true}
            //   required
            //   className="!mb-0"
            // />
          }

          <FloatingSelect
            label="Assets Ordered"
            options={orderedAssets}
            value={filters.asset_ids}
            onChange={v =>
              setFilters(prevFilters => ({
                ...prevFilters,
                asset_ids: v,
              }))
            }
            isMulti={true}
            required
            className="!mb-0"
          />
        </div>
        <div className="">
          <FloatingSelect
            label="Your company"
            options={currentUserCompaniesAsOp}
            value={filters.company}
            onChange={v =>
              setFilters(prevFilters => ({
                ...prevFilters,
                company: v,
              }))
            }
            // isMulti={true}
            required
            className="!mb-0"
          />
        </div>
        {(userType.brandManager === true ||
          userType.retailerManager === true) && (
          <div className="">
            <FloatingSelect
              label="User who ordered"
              options={companyUsers}
              value={filters.users}
              onChange={v =>
                setFilters(prevFilters => ({
                  ...prevFilters,
                  users: v,
                }))
              }
              isMulti={true}
              className="!mb-0"
            />
          </div>
        )}
        <div className="">
          <FloatingSelect
            label="Delivery address"
            options={deliveryLocations}
            value={filters.delivery_locations}
            onChange={v =>
              setFilters(prevFilters => ({
                ...prevFilters,
                delivery_locations: v,
              }))
            }
            isMulti={true}
            className="!mb-0"
          />
        </div>
        <div
          className=""
          css={css`
            &&& {
              > div {
                margin-bottom: 0 !important;
                > div:first-child {
                  height: 62px !important;
                  display: flex;
                  align-items: center;
                }
                > div:nth-child(2) {
                  margin-top: 10px;
                }
              }
            }
          `}
        >
          <FloatingDateRange
            name="range"
            label="Date range"
            dateRange={dateRange}
            setDateRange={v => {
              setDateRange(v)
              setFilters(prevFilters => ({
                ...prevFilters,
                date_range: v,
              }))
            }}
          />
        </div>
        <div className="text-center md:text-left">
          <FilterSelect
            options={[
              {
                label: "All",
                value: "all",
              },
              {
                label: "Processing & Partially Fulfilled",
                value: "processing",
              },
              {
                label: "Cancelled",
                value: "canceled",
              },
              {
                label: "Closed",
                value: "fulfilled",
              }
            ]}
            label="All Order Status"
            onChange={v => {
              setFilters(prevFilters => ({
                ...prevFilters,
                status: v,
              }))
            }}
            className={"!border-0"}
            fromRight
          />
        </div>
      </div>
    </div>
  )
}

export default MyOrdersFilter
