/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useState, useRef, useEffect, useContext } from "react"
import { Table, Tr, Td } from "./TableElements"
import Tag from "../Elements/Tag"
import ImageCardSmall from "../Elements/ImageCardSmall"
import Button from "../Elements/Button"
import Modal from "../Modal/Modal"
import { Link } from "gatsby"
import ImageCardSmallPng from "../../assets/images/image_coming_soon.png"
import CircleAvatar from "../Elements/CircleAvatar"
import ReactToPrint from "react-to-print"
import MyOrdersModal from "../../templates/Dashboard/MyOrders/MyOrdersModal"
import { myEntitySuggestionsByBody } from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"

export default function MyOrdersTable({ data }) {
  const { token } = useContext(StateContext).state
  const { userType } = useContext(AuthenticationContext)

  console.log(data)
  const [openOrder, setOpenOrder] = useState(null)

  let cols = [
    "Order ID",
    "Shipment Number",
    "Order Date",
    "User",
    "Delivery Address",
    "Items Ordered",
    "Qty Ordered",
    "Order Status",
  ]
  if (userType.brandManager === true || userType.brandEditor === true)
    cols = [
      "Order ID",
      "Shipment Number",
      "Order Date",
      "User",
      "Delivery Address",
      "Items Ordered",
      "Qty Ordered",
      "Items Cost",
      "Order Status",
    ]

  return (
    <Table
      columns={cols}
      className={"!whitespace-normal"}
      padding={`max-xl:!px-2`}
    >
      <MyOrdersModal openOrder={openOrder} setOpenOrder={setOpenOrder} />
      {data.map((order, k) => {
        const totalQty = order.items.reduce(
          (acc, item) => acc + item.quantity,
          0
        )
        return (
          <Tr
            key={k}
            onClick={() => setOpenOrder(order)}
            className={"cursor-pointer"}
          >
            <Td className={"max-xl:!px-2"}>
              <span className="text-med-14 !whitespace-normal">
                {order.order_id.replaceAll("Order", "")}
              </span>
            </Td>
            <Td className={"max-xl:!px-2"}>
              <span className="text-med-14">
                {order?.shipment_number ? order?.shipment_number : "N/A"}
              </span>
            </Td>
            <Td className={"max-xl:!px-2"}>{order.order_date}</Td>
            <Td className={"max-xl:!px-2"}>
              <span className="text-med-14 max-xl:!whitespace-normal">
                {order.first_name} {order.last_name}
              </span>
            </Td>
            <Td className="!whitespace-normal">{order.location_name}</Td>
            <Td className={"max-xl:!px-2"}>
              <div className="flex items-center">
                <div className="text-black">
                  {order.items.length} asset{order.items.length > 1 && "s"}
                </div>
              </div>
            </Td>
            <Td className={"max-xl:!px-2"}>
              <div className="flex items-center !whitespace-normal">
                <div className="text-black">{totalQty} units</div>
              </div>
            </Td>
            {(userType.brandManager === true ||
              userType.brandEditor === true) && (
              <Td className={"max-xl:!px-2"}>
                <div className="flex items-center">
                  <div className="text-black">${order.costs}</div>
                </div>
              </Td>
            )}
            {/*<Td>{order.items.map(v => v.sku)}</Td>*/}

            <Td className={"max-xl:!px-2"}>
              <div className="flex">
                <Tag red={order.status === "Cancelled" ? true : false}>
                  {order.status}
                </Tag>
              </div>
            </Td>

            {/* <Td>
              <a target="_blank" href={order.status_url} className="">
                <div className="flex items-center space-x-2">
                  <span>Shipment</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={1}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                    />
                  </svg>
                </div>
              </a>
            </Td> */}
            {/* <Td>
              <Button pink onClick={() => setOpenOrder(order)}>
                View
              </Button>
            </Td> */}
          </Tr>
        )
      })}
    </Table>
  )
}
