/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useState } from "react"
import Button from "../Elements/Button"
import ImageCardSmall from "../Elements/ImageCardSmall"
import CircleAvatar from "../Elements/CircleAvatar"
import MetaUpdated from "../Elements/MetaUpdated"
import { Link } from "gatsby"
import { verifiedHost } from "../../helpers/HostHelper"
import { Table, Tr, Td } from "./TableElements"
import ImageCardSmallPng from "../../assets/images/image_coming_soon.png"
import MyOrdersModal from "../../templates/Dashboard/MyOrders/MyOrdersModal"

export default function DownloadTable({ data, token, toggleNotification }) {
  // console.log(data)
  const [openOrder, setOpenOrder] = useState(null)

  const onDownload = async id => {
    const rand = () => Math.floor(100000000000 + Math.random() * 900000000000)
    let query = `${rand()}${id}${rand()}`
    query = btoa(query)
    const url = `${verifiedHost}/api/download?q=${query}`
    window.open(url, "_blank").focus()
  }
  return (
    <Table
      columns={[
        "Order ID",
        "Order Date",
        "User",
        "Delivery Address",
        // "Title",
        // "Brand",
        "File Type(s)",
        // "Actions",
      ]}
      // overflowVisible
    >
      <MyOrdersModal
        openOrder={openOrder}
        setOpenOrder={setOpenOrder}
        digital={true}
        onDownload={onDownload}
      />
      {data.map((item, k) => (
        <Tr
          key={k}
          onClick={() => setOpenOrder(item)}
          className={"cursor-pointer"}
        >
          <Td className={"max-xl:!px-2"}>
            <span className="text-med-14  !break-all whitespace-normal">
              {item.order_id.replaceAll("Order", "")}
            </span>
          </Td>
          <Td>{item.order_date}</Td>
          <Td className="!whitespace-normal">
            {item.first_name} {item.last_name}
          </Td>
          <Td className="!whitespace-normal">{item.location_name}</Td>

          {
            //   <Td className="!whitespace-normal !w-[400px] !max-w-[400px] !min-w-[400px]">
            //   <Link
            //     to={`/asset/${item.asset_id}`}
            //     className="flex items-center space-x-4"
            //   >
            //     <ImageCardSmall
            //       image={item.asset_cover_image || ImageCardSmallPng}
            //       alt={item.title}
            //     />
            //     <div className="flex flex-col">
            //       <span
            //         css={css`
            //           line-height: 1.5;
            //         `}
            //       >
            //         {item.title}
            //       </span>
            //       <MetaUpdated
            //         lineHeight={1.5}
            //         text={"Last Updated " + item.asset_updated}
            //       />
            //     </div>
            //   </Link>
            // </Td>
          }
          {
            //   <Td>
            //   <Link to={`/brand/${item.brand?.id}`}>
            //     <CircleAvatar
            //       sm
            //       border
            //       text={item.brand_name}
            //       alt={item.brand_name}
            //       image={item.brand_logo}
            //     />
            //   </Link>
            // </Td>
          }
          <Td>
            <div className="flex flex-col text-sm text-gray-500">
              {Array.from(new Set(item.file_types?.split(","))).map((i, k) => (
                <span className="uppercase text-semibold-11">
                  {i
                    .replaceAll("video/", "")
                    .replaceAll("image/", "")
                    .replaceAll("audio/", "")
                    .replaceAll("application/", "")}
                </span>
              ))}
            </div>
          </Td>

          {/* <Td>{item.last_downloaded}</Td> */}
          {
            //  <Td>
            //   <Button
            //     pink
            //     onClick={() => {
            //       onDownload(item.asset_id)
            //     }}
            //   >
            //     Download
            //   </Button>
            // </Td>
          }
        </Tr>
      ))}
    </Table>
  )
}
