/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useEffect, useState, useRef } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import LayoutDashborad from "../../components/Layout/Dashboard"
import { Tab } from "@headlessui/react"
import { classNames } from "../../utils"
import MetaDelivery from "../../components/Elements/MetaDelivery"
import MetaDownload from "../../components/Elements/MetaDownload"
import Digital from "../../templates/Dashboard/MyOrders/Digital"
import Physical from "../../templates/Dashboard/MyOrders/Physical"
import { getOnSiteMarketingCustomLocation } from "../../helpers/DrupalHelper"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import StateContext from "../../context/state/StateContext"
import AdRegion from "../../components/Elements/Listing/AdRegion"
import BannerSlider from "../../components/Commons/BannerSlider"
import MyOrdersFilter from "../../components/MyOrdersFilter"
import PageSubtitle from "../../components/PageSubtitle"
import MyOrdersProvider from "../../context/MyOrders/MyOrdersProvider"
const Tabs = props => {
  const tabs = [
    {
      name: (
        <div className="flex flex-row-reverse">
          <span>Physical Assets</span>
          <MetaDelivery noText black />
        </div>
      ),
      href: "#",
      component: false,
    },
    {
      name: (
        <div className="flex flex-row-reverse">
          <span>Digital Assets</span>
          <MetaDownload noText black />
        </div>
      ),
      href: "#",
      component: false,
    },
  ]

  return (
    <div>
      <Tab.Group defaultIndex={0}>
        <Tab.List className="-mb-px flex space-x-8 border-b border-[#EBEBEB]">
          {tabs.map((item, k) => (
            <Tab
              key={k}
              className={({ selected }) =>
                classNames(
                  selected
                    ? "border-[#222] text-[#222]"
                    : "border-transparent text-[#222] hover:text-gray-500",
                  "whitespace-nowrap pb-[28px] px-1 border-b-2 text-med-16"
                )
              }
            >
              {item.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="pt-[30px]">
          <Tab.Panel key={0}>
            <Physical />
          </Tab.Panel>
          <Tab.Panel key={1}>
            <Digital />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
function MyOrders() {
  const { state } = useContext(StateContext)
  const { userType } = useContext(AuthenticationContext)
  const [adCode, setAdCode] = useState("")
  const [hero, setHero] = useState([])

  const { token } = state
  console.log(userType)
  useEffect(() => {
    if (userType.retailerManager || userType.retailerEditor) {
      getOnSiteMarketingCustomLocation(token, "20").then(res => {
        res.items?.length && setHero(res.items[0]["items"])
      })
    }
  }, [])
  const ref = useRef()
  return (
    <LayoutDashborad
      // heading="My Orders"
      metaTitle="My Orders"
      headingMargin={
        userType.brandEditor ? "mt-[130px]" : !userType.brandManager && "mt-20"
      }
    >
      <MyOrdersProvider>
        {(userType.retailerManager || userType.retailerEditor) && (
          <BannerSlider items={hero} />
        )}
        <PageSubtitle title="Past physical orders and digital downloads by you or others in your company." />
        <div className="flex mb-4" />
        <MyOrdersFilter />
        <Tabs />
      </MyOrdersProvider>
    </LayoutDashborad>
  )
}

export default MyOrders
